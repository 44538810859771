.achievement {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem;
}

.achievement-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 0.4;
    width: 90%;
}

.achievement-body h1 {
    font-family: var(--primaryFont);
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
}

.achievement-body h4 {
    font-size: 1.75rem;
    font-family: 'Caveat', cursive;
    font-weight: 400;
}

.achievement-cards {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 5%;
    width: 100%;
    flex: 0.6;
}

.achievement-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 170px;
    padding: 1.5rem;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    transition: background-color 200ms ease-in-out;
    width: 100%;
    position: relative;
}

.achievecard-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 65%;
   
}

.achievecard-details2 {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    font-family: var(--primaryFont);
    font-size: 18px;
    position: absolute;
    bottom: 7px;
    left: 25px;
}

.achievecard-details2 h5 {
   width: 50%;
}

.achievecard-field {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.achievecard-field svg {
    margin-right: 6px;
}

.achievecard-details1 h2 {
    font-family: var(--primaryFont);
    font-weight: 500;
    font-size: 1.225rem;
    line-height: 110%;
    margin-bottom: 6px;
}

.achievecard-details1 p {
    font-size: 1.10rem;
    font-family: Roboto;
    font-weight: 400;
}

.achievecard-imgcontainer {
    width: 23%;
}

.achievecard-imgcontainer > img {
    width: 150px;
    height: 130px;
    border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1380px) {
    .achievement-card {
        justify-content: flex-start;
    }

    .achievement-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        flex: 0.3;
        width: 90%;
    }
    .achievement-body h1 {
        font-family: var(--primaryFont);
        font-size: 3.1rem;
    }
    
    .achievement-cards {
        padding: 1rem;
        margin-left: 1%;
        width: 100%;
        flex: 0.7;
    }
    .achievecard-imgcontainer {
       margin-left: 1rem;
    }

}

@media screen and (max-width: 992px) {
    .achievement{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 2rem;
    }


    .achievement-body {
        width: 100%;
    }

    .achievement-body h1 {
        font-size: 2.5rem;
    }

    .achievement-cards {
        margin-left: 0%;
        padding: 1rem 0;
        width: 80%;
        margin-top: 1rem;
    }

    .achievement-card {
        margin-left: 0px;
        height: fit-content;
    }

    .achievecard-content {
        width: 70%;
    }

    .achievecard-details1 {
        margin-bottom: 20px;
    }

    .achievecard-details1 h2 {
        font-size: 1.15rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }

    .achievecard-details2 {
        width: 85%;
        position: absolute;
        bottom: 7px;
        left: 25px;
    }

    .achievecard-imgcontainer {
        width: 30%;
        display: flex;
       justify-content: center;
    }

    .achievecard-imgcontainer > img {
        width: 80px;
        height: 70px;
    }
    
}

@media screen and (max-width: 600px) {
    .achievement{
        padding: 1rem;
    }
    .achievement-cards {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    .achievement-body h4 {
        font-size: 1.5rem;
    }

    .achievecard-details1 p {
        font-size: 0.95rem;
    }
    .achievecard-imgcontainer > img {
        width: 70px;
        height: 60px;
    }

}

